import React from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

const ESLibraryNavLinks = () => {
    return ( 
        <div className="page-header fixed-top d-flex justify-content-between align-items-center container">
            <div>
                <Link to="/libraries" className="text-icon">
                    <i className="lni lni-chevron-left"></i>
                </Link>
            </div>
            <div className="navbar-title text-left">
                Spanish <b>Library</b>
            </div>
            <div>
                <Link to="/" className="text-icon">
                    <i className="lni lni-search-alt"></i>
                </Link>
            </div>
            <div>
                <Link to="/" className="text-icon">
                    <i className="lni lni-plus"></i>
                </Link>
            </div>
        </div>
    );
};

export default ESLibraryNavLinks;