import React, { Component, useState } from 'react'
import TextList from '../texts/TextList'
import CreateText from '../texts/CreateText'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Tabbar from '../../components/layout/Tabbar'
import LibraryEntry from '../libraries/LibraryEntry'
import { Modal } from 'react-bootstrap';
import { signOut } from '../../store/actions/authActions'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleClose = () => {
        this.setState({show: false});
    }

    handleShow = () => {
        this.setState({show: true});
    };

    handleSignOut = () => {
        document.body.classList.remove("show-menu");
        document.body.classList.add("hide-menu");
        this.props.signOut();
    }

    render() {
      const { libraries, profile, auth } = this.props;

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div className="app-container">
            <div className="side-menu">
                <img id="user-image" src="/assets/user_image.jpeg" width="64px" height="64px"/>
                <ul>
                    <li><a href="/home"><i className="lni lni-home"></i>Home</a></li>
                    <li><a href="/libraries/en"><i className="lni lni-list"></i>English Library</a></li>
                    <li><a href="/libraries/es"><i className="lni lni-list"></i>Spanish Library</a></li>
                    <li><a href="/stories"><i className="lni lni-book"></i>Stories</a></li>
                    <li><a href="/store"><i className="lni lni-cart"></i>Store</a></li>
                    <li><a href="/settings"><i className="lni lni-cog"></i>Settings</a></li>
                    <li><a href="/purchased"><i className="lni lni-wallet"></i>Purchased</a></li>
                    <li><a onClick={this.handleSignOut} href="#"><i className="lni lni-exit"></i>Sign Out</a></li>
                </ul>
                <ul className="sub-menu">
                    <li>Cookies</li>
                    <li>Privacy</li>
                    <li>Terms of Use</li>
                </ul>
                <div className="d-flex app-footer align-items-center">
                    <div>
                        <img src="logo-fig-white.svg" height="54" alt="Fig Logo" />
                    </div>
                    <div>
                        <p>Fig App</p>
                        <p><i>v1.25</i></p>
                        <span>&copy; Innovation Kitchen</span>
                    </div>
                </div>
            </div>
            <Navbar page={'home'}/>
            
            <div className="home container p-32 pt-60">
                <p className="page-title text-h2">Hello<br/> <b>{profile.first_name}</b></p>
                <div className="text-small d-flex justify-content-between">
                    <span>New in Store</span>
                    <span><a href="/store"><b>See All</b></a></span>
                </div>
                <div className="featured">
                    <div className="d-flex list">
                        <img className="product-card-image" src="/assets/nis_1.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_2.png" onClick={this.handleShow}  />
                        <img className="product-card-image" src="/assets/nis_8.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_3.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_4.jpg" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_5.jpg" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_6.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_7.png" onClick={this.handleShow}/>
                    </div>
                </div>
                <Modal className="animated fadeInUpBig" dialogClassName="modal-dialog-scrollable" show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header>
                        <div className="d-flex justify-content-end">
                            <i className="lni lni-close" onClick={this.handleClose}></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center featured showcase">
                            <img className="product-card-image" src="/assets/nis_7.png"/><br/>
                            <span className="badge badge-dark">STORIES</span>
                            <p className="text-h2">Charlotte's Web</p>
                            <div className="d-flex justify-content-between m-16">
                                <div><span className="t-800">102</span> Words</div>
                                <div><i className="lni lni-heart"></i> 1.5K</div>
                                <div><i className="lni lni-download"></i> 15K</div>
                            </div>
                            <span className="description">E. B. White's Newbery Honor Book is a tender novel of friendship, love, life, and death that will continue to be enjoyed by generations to come. It contains illustrations by Garth Williams, the acclaimed illustrator of E. B. White's Stuart Little and Laura Ingalls Wilder's Little House series, among many other books.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary btn-block t-000">Buy&nbsp;&nbsp;|&nbsp;&nbsp;$0.20</button>
                    </Modal.Footer>
                </Modal>
                <div className="featured hidden">
                    <div className="d-flex list">
                        <div className="card bg-amber">
                            <div className="">
                                <div className="text-small">LANGUAGE</div>
                                <div className="card-title text-h2-bold">
                                    French Library
                                </div>
                                <br/>
                                <div className="card-footer d-flex">
                                    <div className="icon-container text-center align-middle">
                                        <div className="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-deep-orange">
                            <div className="">
                                <div className="text-small">LOCALE</div>
                                <div className="card-title text-h2-bold">
                                    Canada Library
                                </div>
                                <br/>
                                <div className="card-footer d-flex">
                                    <div className="icon-container text-center align-middle">
                                        <div className="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-light-green">
                            <div className="">
                                <div className="text-small">COLLECTIONS</div>
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/>
                                <div className="card-footer d-flex">
                                    <div className="icon-container text-center align-middle">
                                        <div className="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-cyan">
                            <div className="">
                                <div className="text-small">COLLECTIONS</div>
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/>
                                <div className="card-footer d-flex">
                                    <div className="icon-container text-center align-middle">
                                        <div className="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-indigo">
                            <div className="">
                                <div className="text-small">STORIES</div>
                                <div className="card-title text-h2-bold">
                                    Survival Guide
                                </div>
                                <br/>
                                <div className="card-footer d-flex">
                                    <div className="icon-container text-center align-middle">
                                        <div className="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-small hidden d-flex justify-content-between">
                    <span>Your Stories</span>
                    <span><a href="/stories"><b>See All</b></a></span>
                </div>
                <div className="featured hidden your-stories">
                    <div className="d-flex list">
                        <img className="product-card-image" src="/assets/story_1.jpg" />
                        <img className="product-card-image" src="/assets/story_2.png" />
                        <img className="product-card-image" src="/assets/story_3.jpg" />
                        <img className="product-card-image" src="/assets/story_4.jpg" />
                        <img className="product-card-image" src="/assets/story_5.png" />
                        <img className="product-card-image" src="/assets/story_6.jpg" />
                    </div>
                </div>
                <div className="featured hidden your-stories">
                    <div className="d-flex list">
                        <div className="card bg-teal">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Afternoon on the Amazon
                                </div>
                            </div>
                        </div>
                        <div className="card bg-purple">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Eve of the Emperor Penguin
                                </div>
                            </div>
                        </div>
                        <div className="card bg-orange">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    The Magic School Bus on the Ocean Floor
                                </div>
                            </div>
                        </div>
                        <div className="card bg-cyan">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    The Magic School Bus at the Waterworks
                                </div>
                            </div>
                        </div>
                        <div className="card bg-indigo">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    The Magic School Bus in the Hurricane
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="text-small d-flex justify-content-between mb-32">
                    <span>Your Word Libraries</span>
                </div>
                <div className="card-deck">
                {(libraries && libraries.length && libraries.map((library, index) => {
                    return(
                        <a href={'/libraries/' + library.lang }><LibraryEntry library={library} /></a>
                    )
                }))}
                </div>
                <div className="text-small d-flex justify-content-between mb-32">
                    <span>Insights</span>
                </div>
                <div className="card bg-red">
                    <div className="card-title text-h1"></div>
                    <br/><br/><br/>
                </div>
            </div>
            <Tabbar page={'home'}/>  
        </div>
      )
    }
  }
  
const mapStateToProps = (state) => {
  //console.log("WW1", state);
  return {
    stories: state.firestore.ordered.stories,  
    libraries: state.firestore.ordered.libraries,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: 'libraries',
      where: [['user_id', '==', props.auth.uid? props.auth.uid : '']],
      orderBy: [['title']]
    }
  ])
)(Home);