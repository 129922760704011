import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
import { Link } from "react-router-dom"

class SignUp extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signUp(this.state);
    }

    render() {
        const {auth, authError } = this.props;

        if(auth.uid) return <Redirect to='/home' />
        
        return (
            <div id="signIn" className="container p-30 pt-0">
                <img src="logo-fig-1.svg" height="54" alt="Fig Logo" />
                <p className="page-title text-h2">Hello</p>
                <form onSubmit={this.handleSubmit}>
                    <p className="text-small">Fill in your details to sign up</p>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><div class="icon-bg"><i class="lni lni-user"></i></div></span>
                        </div>
                        <input type="email" id="email" onChange={this.handleChange} className="form-control" placeholder="Your email address" aria-label="Email" aria-describedby="basic-addon1"/>
                    </div>
                    <div class="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon2"><div class="icon-bg"><i class="lni lni-key"></i></div></span>
                        </div>
                        <input type="password" id="password" onChange={this.handleChange} className="form-control" placeholder="Your password" aria-label="Password" aria-describedby="basic-addon2"/>
                    </div>
                    <div class="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon3"><div class="icon-bg"><i class="lni lni-user"></i></div></span>
                        </div>
                        <input type="text" id="firstName" onChange={this.handleChange} className="form-control" placeholder="Your first name" aria-label="First Name" aria-describedby="basic-addon3"/>
                    </div>
                    <div class="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon4"><div class="icon-bg"><i class="lni lni-user"></i></div></span>
                        </div>
                        <input type="text" id="lastName" onChange={this.handleChange} className="form-control" placeholder="Your last name" aria-label="Last Name" aria-describedby="basic-addon4"/>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block t-800">Sign Up</button>
                    <div className="text-danger">
                        { authError ? <p>{authError}</p>: null }
                    </div>
                </form>
                <div class="signup-block text-center">
                    <Link to="/signin" className="">
                        <div className="btn-transparent">
                            <button className="btn btn-transparent"><span>Already have an account? <span className="t-800">Sign In</span></span></button>
                            </div>
                    </Link>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
