import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import { Modal } from 'react-bootstrap'


class Stories extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleClose = () => {
        this.setState({show: false});        
    }

    handleShow = () => {
        this.setState({show: true});
    }

    handleClick = (e) => {
        //console.log(e.currentTarget.id);
        window.location.href = '/collections/en/' + e.currentTarget.id;
    }
    
    render() {
      const { texts, auth } = this.props;

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div>
            <Navbar page={'stories'}/>
            <div className="stories container">
                
                <div className="text-small d-flex justify-content-between">
                    <span>Featured Stories</span>
                    <span><b>See All</b></span>
                </div>
                <div className="featured">
                    <div className="d-flex list">
                        <img className="product-card-image" src="/assets/nis_8.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_13.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_6.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_9.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_10.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_11.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_12.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_15.png" onClick={this.handleShow} />
                    </div>
                </div>
                <Modal id="modal" className="animated fadeInUpBig" dialogClassName="modal-dialog-scrollable" show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header>
                        <div className="d-flex justify-content-end">
                            <i className="lni lni-close" onClick={this.handleClose}></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center featured showcase">
                            <img className="product-card-image" src="/assets/nis_8.png"/><br/>
                            <span class="badge badge-dark">COLLECTIONS</span>
                            <p className="text-h2">Car Brands</p>
                            <div class="d-flex justify-content-between m-16">
                                <div><span className="t-800">250</span> Words</div>
                                <div><i className="lni lni-heart"></i> 18K</div>
                                <div><i className="lni lni-download"></i> 9M</div>
                            </div>
                            <span className="description">This collection contains an exhaustive list of car brands across the globe. Some of the unique names that you may not have heard of.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary btn-block t-000">Buy&nbsp;&nbsp;|&nbsp;&nbsp;$0.99</button>
                    </Modal.Footer>
                </Modal>                
                <div className="text-small d-flex justify-content-between mb-32">
                    <span>Your Stories</span>
                </div>
                <div className="card-columns collections-list">
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_1.jpg" />
                    </div>
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_2.png" />
                    </div>
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_3.jpg" />
                    </div>
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_4.jpg" />
                    </div>
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_5.png" />
                    </div>
                    <div class="card">
                        <img className="product-card-image card-img" src="/assets/story_6.jpg" />
                    </div>
                </div>
                <br/><br/><br/><br/>
            </div>
        </div>
      )
    }
  }
  
const mapStateToProps = (state) => {
  //console.log("Inside Library.js ", state);
  return {
    libraries: state.firestore.ordered.libraries,
    texts: state.firestore.ordered.texts,
    auth: state.firebase.auth
  }
}


export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: props.match.params.id + '_texts',
      where: [['userId', '==', props.auth.uid? props.auth.uid : '']],
      orderBy: [['createdAt',]]
    }
  ])
)(Stories);