import React from "react";

const LoadingButton = () => {
  /*
        <button className="btn btn-dark btn-sm" type="button">
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
          <span className="sr-only">Loading...</span>
        </button>
  */
    return ( 
      <div class="icon-container disabled text-center align-middle">
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>

    );
};

export default LoadingButton;