 import firebase from 'firebase/app'
 import 'firebase/firestore'
 import 'firebase/auth'


  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyAfv1DflbR4xv_JVz5iSy8aTk1Clp2UGeo",
    authDomain: "ik-fig-app.firebaseapp.com",
    databaseURL: "https://ik-fig-app.firebaseio.com",
    projectId: "ik-fig-app",
    storageBucket: "ik-fig-app.appspot.com",
    messagingSenderId: "838103287698",
    appId: "1:838103287698:web:e148b1d03656bac336234b",
    measurementId: "G-SNJXKER947"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  //firebase.firestore().settings();
  //firebase.analytics();  

  export default firebase;