import React, { Component, useState } from 'react'
import TextList from '../texts/TextList'
import CreateText from '../texts/CreateText'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Tabbar from '../../components/layout/Tabbar'
import LibraryEntry from '../libraries/LibraryEntry'
import { Modal } from 'react-bootstrap';
import { signOut } from '../../store/actions/authActions'

class Libraries extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleClose = () => {
        this.setState({show: false});
    }

    handleShow = () => {
        this.setState({show: true});
    };

    render() {
      const { libraries, profile, auth } = this.props;

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div className="app-container">
            <Navbar page={'libraries'}/>
            <div className="libraries container p-32 pt-60">
                <div className="text-small d-flex justify-content-between">
                    <span>New in Store</span>
                    <span><a href="/store"><b>See All</b></a></span>
                </div>
                <div className="featured">
                    <div className="d-flex list">
                        <img className="product-card-image" src="/assets/nis_1.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_2.png" onClick={this.handleShow}  />
                        <img className="product-card-image" src="/assets/nis_1.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_2.png" onClick={this.handleShow}  />
                        <img className="product-card-image" src="/assets/nis_1.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_2.png" onClick={this.handleShow}  />
                        <img className="product-card-image" src="/assets/nis_1.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_2.png" onClick={this.handleShow}  />
                    </div>
                </div>
                <Modal className="animated fadeInUpBig" dialogClassName="modal-dialog-scrollable" show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header>
                        <div className="d-flex justify-content-end">
                            <i className="lni lni-close" onClick={this.handleClose}></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center featured showcase">
                            <img className="product-card-image" src="/assets/nis_7.png"/><br/>
                            <span className="badge badge-dark">STORIES</span>
                            <p className="text-h2">Charlotte's Web</p>
                            <div className="d-flex justify-content-between m-16">
                                <div><span className="t-800">102</span> Words</div>
                                <div><i className="lni lni-heart"></i> 1.5K</div>
                                <div><i className="lni lni-download"></i> 15K</div>
                            </div>
                            <span className="description">E. B. White's Newbery Honor Book is a tender novel of friendship, love, life, and death that will continue to be enjoyed by generations to come. It contains illustrations by Garth Williams, the acclaimed illustrator of E. B. White's Stuart Little and Laura Ingalls Wilder's Little House series, among many other books.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary btn-block t-000">Buy&nbsp;&nbsp;|&nbsp;&nbsp;$0.20</button>
                    </Modal.Footer>
                </Modal>
                <div className="text-small d-flex justify-content-between mb-32">
                    <span>Your Word Libraries</span>
                </div>
                <div className="card-deck">
                {(libraries && libraries.length && libraries.map((library, index) => {
                    return(
                        <a href={'/libraries/' + library.lang }><LibraryEntry library={library} /></a>
                    )
                }))}
                </div>
            </div>
            <Tabbar page={'libraries'}/>  
        </div>
      )
    }
  }
  
const mapStateToProps = (state) => {
  //console.log("WW1", state);
  return {
    stories: state.firestore.ordered.stories,  
    libraries: state.firestore.ordered.libraries,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    {
      collection: 'libraries',
      where: [['user_id', '==', props.auth.uid? props.auth.uid : '']],
      orderBy: [['title']]
    }
  ])
)(Libraries);