import React from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

const ENCollectionNavLinks = (props) => {
    var { collection } = props;

    return ( 
        <div className="page-header fixed-top d-flex justify-content-between align-items-center container">
            <div>
                <Link to="/libraries/en" className="text-icon">
                    <i className="lni lni-chevron-left"></i>
                </Link>
            </div>
            <div className="navbar-title text-left">
                <b>{ collection.title ? collection.title: 'All Words' }</b>
            </div>
            <div>
                <Link to={ '/play/en/' + (collection.id ? collection.id: '') } className="text-icon">
                    <i className="lni lni-play"></i>
                </Link>
            </div>
            <div>
                <Link to="/" className="text-icon">
                    <i className="lni lni-more-alt"></i>
                </Link>
            </div>
        </div>
    );
};

export default ENCollectionNavLinks;