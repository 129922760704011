import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import { Modal } from 'react-bootstrap'


class More extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleClose = () => {
        this.setState({show: false});        
    }

    handleShow = () => {
        this.setState({show: true});
    }

    handleClick = (e) => {
        //console.log(e.currentTarget.id);
        window.location.href = '/collections/en/' + e.currentTarget.id;
    }
    
    render() {
      const { texts, auth } = this.props;
      //console.log("I am now called ", texts, auth);

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div>
            <Navbar page={'more'}/>
            <div className="more container text-center">   
                <img id="user-image" src="/assets/user_image.jpeg" width="64px" height="64px"/>
                <div className="text-small hidden d-flex justify-content-between mb-32">
                    <span>Your English Library</span>
                </div>
                <div className="card-columns collections-list">
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/home"><i class="lni lni-home"></i>&nbsp;&nbsp;Home</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/libraries/en"><i class="lni lni-list"></i>&nbsp;&nbsp;English Library</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/libraries/es"><i class="lni lni-list"></i>&nbsp;&nbsp;Spanish Library</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/stories"><i class="lni lni-book"></i>&nbsp;&nbsp;Stories</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/store"><i class="lni lni-cart"></i>&nbsp;&nbsp;Store</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/profile"><i class="lni lni-user"></i>&nbsp;&nbsp;Profile</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/cookies"><i class="lni lni-user"></i>&nbsp;&nbsp;Cookies</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/privacy"><i class="lni lni-user"></i>&nbsp;&nbsp;Privacy</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/terms"><i class="lni lni-user"></i>&nbsp;&nbsp;Terms of Use</a></div>
                            </div>   
                        </div>                   
                    </div>

                </div>
                <div class="d-flex app-footer align-items-center mt-2">
                    <div>
                        <img src="logo-fig-1.svg" height="54" alt="Fig Logo" />
                    </div>
                    <div>
                        <p>Fig App</p>
                        <p><i>v1.25</i></p>
                        <span>&copy; Innovation Kitchen</span>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/><br/>
        </div>
      )
    }
  }

  const mapStateToProps = (state) => {
    //console.log("Inside More.js ", state);
    return {
      libraries: state.firestore.ordered.libraries,
      texts: state.firestore.ordered.texts,
      auth: state.firebase.auth
    }
  }
  
  
  export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
      {
        collection: props.match.params.id + '_texts',
        where: [['userId', '==', props.auth.uid? props.auth.uid : '']],
        orderBy: [['createdAt',]]
      }
    ])
  )(More);  
