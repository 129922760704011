import React, { Component } from "react";

class Tabbar extends Component {
    componentDidMount() {
        if (!window.lastScrollY) window.lastScrollY = window.scrollY;
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = (e) => {
        /*
        if (window.lastScrollY < window.scrollY) {
            document.querySelector(".tab-bar").classList.add("fadeOutDown");
            if (document.querySelector(".createText")) document.querySelector(".createText").classList.add("fadeOutDown");
        } else {
            document.querySelector(".tab-bar").classList.remove("fadeOutDown");
            if (document.querySelector(".createText")) document.querySelector(".createText").classList.remove("fadeOutDown");
        }
      
        window.lastScrollY = window.scrollY;
        */
    }

    render() {
        //console.log("Inside Tabbar, page = ", this.props.page);
        return (
            <div class="tab-bar-parent fixed-bottom container">
                <div class="tab-bar animated fadeInUp" >
                    <ul class="d-flex justify-content-between align-items-center">
                        <li className={this.props.page==='home' ? "selected animated fadeInUp zoomIn": "animated fadeInUp"}><a href="/home"><i class="lni lni-home"></i> <span>Home</span></a></li>
                        <li className={(this.props.page==='libraries' || this.props.page==='en-library' || this.props.page==='es-library') ? "selected animated fadeInUp zoomIn": "animated fadeInUp"}><a href="/libraries"><i class="lni lni-grid-alt"></i> <span>Libraries</span></a></li>
                        <li className={this.props.page==='store' ? "selected animated fadeInUp zoomIn": "animated fadeInUp"}><a href="/store"><i class="lni lni-cart"></i> <span>Store</span></a></li>
                        <li className={this.props.page==='profile' ? "selected animated fadeInUp zoomIn": "animated fadeInUp"}><a href="/profile"><i class="lni lni-user"></i> <span>Profile</span></a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Tabbar;