import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import AudioPlayer from "../media/AudioPlayer"
import LoadingButton from "../layout/LoadingButton"
import LexicalEntry from "../../components/texts/LexicalEntry"
import Loading from "../texts/Loading"
import Error from "../texts/Error"
import NoResults from "../texts/NoResults"

class TextDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
          text: {},  
          oxfordCard: {
            hasError: false,
            isLoading: true,
            results: {},
          },
          wikiCard: {
            hasError: false,
            isLoading: true,
            results: {},
          }
        };
    }

    componentDidMount() {

      //Set the text in state
      const textStr = decodeURI(window.location.search.substr(1));
      const text = JSON.parse(textStr);
      this.setState({text: text});

      //Fetch Oxford card data
      var oxfordUrl = 'https://us-central1-ik-fig-app.cloudfunctions.net/getDataFromOxford';
      oxfordUrl += '?text=' + encodeURI(text.text);
      fetch(oxfordUrl)
        .then(res => res.json())
        .then(
          results => {
            try {
              results = JSON.parse(results);
            } catch(ex) {
              console.error(ex);
            }
            this.setState({
              oxfordCard: {
                isLoading: false,
                results: results
              }
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              oxfordCard: {
                isLoading: false,
                hasError: true,
                error: error
              }
            });
          }
        );

      //Fetch wiki data  
      var wikiUrl = "https://us-central1-ik-fig-app.cloudfunctions.net/getDataFromWiki";
      wikiUrl += '?text=' + encodeURI(text.text);
      //console.log(wikiUrl);
      fetch(wikiUrl)
      .then(res => res.json())
      .then(
        results => {
          try {
            results = JSON.parse(results);
          } catch(ex) {
            console.error(ex);
          }
          this.setState({
            wikiCard: {
              isLoading: false,
              results: results
            }
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            wikiCard: {
              isLoading: false,
              hasError: true,
              error: error
            }
          });
        }
      );

    }

    renderOxfordCard = () => {
      const { hasError, isLoading, results, error } = this.state.oxfordCard;
      //console.log(results.error);

      if (isLoading) return <Loading />;
      if (hasError) return <Error error={error.message}/>;
      if (results.length < 1 || results.error) {
        return <NoResults message={results.error}/>
      }

      return (
        <div>
          { results.results && results.results[0].lexicalEntries.map(lexicalEntry => {
              //console.log(lexicalEntry);
              return (
                <LexicalEntry lexicalEntry={lexicalEntry}/>
              )
          })}            
        </div>
      );
    }

    renderESOxfordCard = () => {
      return (
        <p className="no-results">The definition of Spanish word is not supported at this time. Sorry for the incovenience.</p>
      )
    }

    renderWikiCard = () => {
      const { hasError, isLoading, results, error } = this.state.wikiCard;
      if (isLoading) return <Loading />;
      if (hasError) return <Error error={error.message}/>;
      if (results.length < 1) return <NoResults />;

      return (
        <div>
          {(results && results.length && results[1].map((wikiEntry, index) => {
            return (
              <a href={results[3][index]} target="_blank">
                <div className="wiki-entry-container">
                  <div className="bookmark-container rotate"><i className="lni lni-bookmark"></i></div>
                  <div className="wiki-entry-title">{wikiEntry}</div>
                  <p>
                    <span>{results[2][index]}</span>
                  </p>
                </div>
              </a>
            )
          }))}
        </div>
      );

    }
    
    render() {
      const { texts, auth } = this.props;
      if(!auth.uid) return <Redirect to='/signin' />
      //console.log(this.state);
      const {text} = this.state;
      const enUSAudioPlayer = text.en_us_audio_url ? <AudioPlayer label="US" url={text.en_us_audio_url}/> : <LoadingButton />;
      const enGBAudioPlayer = text.en_gb_audio_url ? <AudioPlayer label="GB" url={text.en_gb_audio_url}/> : <LoadingButton />;
      //const enAUAudioPlayer = text.en_au_audio_url ? <AudioPlayer label="AUS" url={text.en_au_audio_url}/> : <LoadingButton />;
      //const enINAudioPlayer = text.en_in_audio_url ? <AudioPlayer label="IND" url={text.en_in_audio_url}/> : <LoadingButton />;
      const esESAudioPlayer = text.es_es_audio_url ? <AudioPlayer label="ES" url={text.es_es_audio_url}/> : <LoadingButton />;
    
      //const audioPlayer = text.audioUrl ? <AudioPlayer url={text.audioUrl} /> : <LoadingButton />;
      const playAudio = (e) => {
        //find the child element and trigger it's click event
        e.currentTarget.getElementsByClassName('playIcon')[0].click();
      };
  
      return (
          <div>
              <Navbar page={text.lang + '-word'} backUrl={'/collections/' + text.lang + '/' + (text.collection_id === '' ? 'all': text.collection_id)}/>
              <div className="word container">
                  <div className="text-h1 text-center">{text.text}</div>
                  <div className="actions d-flex justify-content-center mb-32">
                  {text.lang === 'es' ? <div className="actionButton">{esESAudioPlayer}</div>: ''}
                  {text.lang === 'en' ? <div className="actionButton">{enUSAudioPlayer}</div>: ''}
                  {text.lang === 'en' ? <div className="actionButton">{enGBAudioPlayer}</div>: ''}
                  </div>
                  <div className="card-columns">
                      <div className="card">
                          <div className="card-header">
                            <img src="/logo-oxford.svg" height="14"/> OXFORD
                          </div>
                          <div className="card-body">
                            {text.lang === 'en'? this.renderOxfordCard(): this.renderESOxfordCard()}
                          </div>
                      </div>
                      <div className="card">
                          <div className="card-header">
                            <img src="/logo-wiki.svg" height="14"/> WIKIPEDIA
                          </div>
                          <div className="card-body">
                            {this.renderWikiCard()}
                          </div>
                      </div>
  
                  </div>
              </div>
          </div>
        )
            

    }
  }
  
const mapStateToProps = (state) => {
  //console.log("WW", state);
  return {
    texts: state.firestore.ordered.texts,
    auth: state.firebase.auth
  }
}


export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'texts',
      where: [['userId', '==', props.auth.uid? props.auth.uid : '']],
      orderBy: [['createdAt', 'desc']]
    }
  ])
)(TextDetails);