import React, { Component } from "react";
import { Link } from "react-router-dom";
import SignedOutLinks from "./SignedOutLinks";
import SignedInLinks from "./SignedInLinks";
import WordNavLinks from "./WordNavLinks";
import ENCollectionNavLinks from "./ENCollectionNavLinks";
import ESCollectionNavLinks from "./ESCollectionNavLinks";
import LibrariesNavLinks from "./LibrariesNavLinks";
import ENLibraryNavLinks from "./ENLibraryNavLinks";
import ESLibraryNavLinks from "./ESLibraryNavLinks";
import HomeNavLinks from "./HomeNavLinks";
import StoriesNavLinks from './StoriesNavLinks';
import StoreNavLinks from './StoreNavLinks';
import MoreNavLinks from './MoreNavLinks';
import ProfileNavLinks from './ProfileNavLinks'
import Tabbar from "./Tabbar";
import { connect } from "react-redux";

//const Navbar = (props) => {
class Navbar extends Component {
  state = {
    isToggleOn: false
  }

  handleClick = (e) => {
      this.setState({
          isToggleOn: !this.state.isToggleOn
      })

      //this.state.isToggleOn ? console.log('open'): console.log('closed');
  }

  toggleSideMenu() {
    //console.log("Inside toggleSideMenu");

    const x = window.matchMedia('(min-width: 768px)');
    if(x.matches) {
      document.body.classList.remove('hide-menu');
      document.body.classList.add('show-menu');
    } else {
      document.body.classList.remove('show-menu');
      document.body.classList.add('hide-menu');
    }
  }

  renderSwitch(page) {
    //console.log("page = " + page);
    switch(page) {
      case 'home':
        return <HomeNavLinks />;
      case 'libraries':
        return <LibrariesNavLinks />;
      case 'en-library':
        return <ENLibraryNavLinks />;
      case 'es-library':
        return <ESLibraryNavLinks />;
      case 'en-collection':
        return <ENCollectionNavLinks collection={this.props.collection}/>;
      case 'es-collection':
        return <ESCollectionNavLinks collection={this.props.collection}/>;
      case 'en-word':
        return <WordNavLinks lang="en" backUrl={this.props.backUrl}/>;
      case 'es-word':
        return <WordNavLinks lang="es" backUrl={this.props.backUrl}/>;
      case 'stories':
        return <StoriesNavLinks />;
      case 'store':
        return <StoreNavLinks />;
      case 'more':
        return <MoreNavLinks />;
      case 'profile':
        return <ProfileNavLinks />;
      default:
        return '';
    }
  }

  render() {
    const { auth } = this.props;
    const { page } = this.props;
    //console.log("Inside Navbar : ", window.matchMedia('(min-width: 768px)'));
    
    //Keep the sidemenu open if the screen is bigger
    //this.toggleSideMenu(); dont do it now

    const links = auth.uid ? (
      <SignedInLinks profile={this.props.profile} />
    ) : (
      <SignedOutLinks />
    );

    return (
      <div>
        {this.renderSwitch(page)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};
export default connect(mapStateToProps)(Navbar);
