import React, { Component } from 'react'
import TextList from '../texts/TextList'
import CreateCollection from './CreateCollection'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import Tabbar from '../layout/Tabbar'
import { Modal } from 'react-bootstrap'


class ENLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showCreateCollection: false
        };
    }

    handleClose = () => {
        this.setState({show: false});        
    }

    handleShow = () => {
        this.setState({show: true});
    }

    handleClick = (e) => {
        //console.log(e.currentTarget.id);
        window.location.href = '/collections/en/' + e.currentTarget.id;
    }

    render() {
      const { collections, auth, libraryId } = this.props;
      //console.log("I am now called ", collections, auth);

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div>
            <Navbar page={'en-library'}/>
            <div className="library container">
                
                <div className="text-small d-flex justify-content-between hidden">
                    <span>Locales</span>
                </div>
                <div class="mt-32 mb-32 hidden">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="icon-container text-center align-middle">
                            <div class=""><i className="lni lni-check-mark-circle"></i> USA</div>
                            </div>
                        </div>
                        <div>
                            <div class="icon-container text-center align-middle">
                            <div class=""><i className="lni lni-check-mark-circle"></i> UK</div>
                            </div>
                        </div>
                        <div>
                            <div class="icon-container text-center align-middle">
                            <div class=""><i className="lni lni-check-mark-circle"></i> AUS</div>
                            </div>
                        </div>
                        <div>
                            <div class="icon-container text-center align-middle">
                            <div class=""><i className="lni lni-check-mark-circle"></i> IND</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="text-small d-flex justify-content-between">
                    <span>Featured Collections</span>
                    <span><b>See All</b></span>
                </div>
                <div className="featured">
                    <div className="d-flex list">
                        <img className="product-card-image" src="/assets/nis_8.png" onClick={this.handleShow}/>
                        <img className="product-card-image" src="/assets/nis_13.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_6.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_9.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_10.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_11.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_12.png" onClick={this.handleShow} />
                        <img className="product-card-image" src="/assets/nis_15.png" onClick={this.handleShow} />
                    </div>
                </div>
                <Modal id="modal" className="animated fadeInUpBig" dialogClassName="modal-dialog-scrollable" show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header>
                        <div className="d-flex justify-content-end">
                            <i className="lni lni-close" onClick={this.handleClose}></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center featured showcase">
                            <img className="product-card-image" src="/assets/nis_8.png"/><br/>
                            <span class="badge badge-dark">COLLECTIONS</span>
                            <p className="text-h2">Car Brands</p>
                            <div class="d-flex justify-content-between m-16">
                                <div><span className="t-800">250</span> Words</div>
                                <div><i className="lni lni-heart"></i> 18K</div>
                                <div><i className="lni lni-download"></i> 9M</div>
                            </div>
                            <span className="description">This collection contains an exhaustive list of car brands across the globe. Some of the unique names that you may not have heard of.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary btn-block t-000">Buy&nbsp;&nbsp;|&nbsp;&nbsp;$0.99</button>
                    </Modal.Footer>
                </Modal>                
                <div className="featured hidden">
                    <div className="d-flex list">
                        <div className="card bg-deep-purple">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/><br/>
                                <div className="card-footer d-flex">
                                    <div class="icon-container text-center align-middle">
                                        <div class="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-deep-orange">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/><br/>
                                <div className="card-footer d-flex">
                                    <div class="icon-container text-center align-middle">
                                        <div class="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-light-green">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/><br/>
                                <div className="card-footer d-flex">
                                    <div class="icon-container text-center align-middle">
                                        <div class="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-cyan">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/><br/>
                                <div className="card-footer d-flex">
                                    <div class="icon-container text-center align-middle">
                                        <div class="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>
                        </div>
                        <div className="card bg-indigo">
                            <div className="">
                                <div className="card-title text-h2-bold">
                                    Flags of the World
                                </div>
                                <br/><br/>
                                <div className="card-footer d-flex">
                                    <div class="icon-container text-center align-middle">
                                        <div class="">$ 2.99</div>
                                    </div><br/>
                                </div>                   
                            </div>

                        </div>

                    </div>
                </div>
                <div className="text-small d-flex justify-content-between mb-32">
                    <span>Your English Library</span>
                </div>
                <div className="card-columns collections-list">
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center justify-content-between">
                            <div className="">
                                <div className="card-text">All Words</div>
                            </div>   
                            <div className="actions">
                                <i className="lni lni-chevron-right"></i>
                            </div>  
                        </div>                   
                    </div>
                    { collections && collections.map(collection => {
                        const link = '/collections/en/' + collection.id ;
                        return (
                            <a href={link}>
                                <div class="card" id="1" onClick={this.handleClick}>
                                    <div className="card-body d-flex align-items-center justify-content-between">
                                        <div className="">
                                            <div className="card-text">{collection.title}</div>
                                        </div>   
                                        <div className="actions">
                                            <i className="lni lni-chevron-right"></i>
                                        </div>  
                                    </div>                   
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
            <Tabbar page={'en-library'}/>          
        </div>
      )
    }
  }
  
const mapStateToProps = (state) => {
  //console.log("Inside Library.js ", state);
  return {
    collections: state.firestore.ordered.en_collections,
    auth: state.firebase.auth,
    libraryId: '1234'
  }
}


export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'en_collections',
      where: [['user_id', '==', props.auth.uid? props.auth.uid : '']],
      orderBy: [['created_at', 'desc']]
    }
  ])
)(ENLibrary);