import React from "react";

const NoResults = ({message}) => {
    return ( 
        <p class="no-results">
            { message?  message: "No matching results found." }
        </p>
    );
};

export default NoResults;