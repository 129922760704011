import React, { Component } from 'react'

class AudioPlayer extends Component {
    state = {
      play: false
    }

    audio = ''

    togglePlay = (e) => {
      if (this.audio === '') {
       this.audio = new Audio(this.props.url);
      }
      this.audio.play();
      //this.setState({ play: !this.state.play }, () => {
        //this.state.play ? this.audio.play() : this.audio.pause();
      //});
      e.preventDefault();
    }
  
    render() {
      //console.log(this.props.url, this.audio);  
      //          <button type="button" className="btn btn-dark btn-sm" onClick={this.togglePlay}>{this.state.play ? 'Pause' : 'Play'}</button>
      /*
      return (
        <div class="icon-container text-center align-middle">
          <div class="playIcon" title={this.state.play ? 'Pause' : 'Play'} onClick={this.togglePlay}><i className="lni lni-play"></i> {this.props.label}</div>
        </div>
      );*/
      return (
        <div className="icon-container text-center align-middle">
          <div className="playIcon" title={this.state.play ? 'Pause' : 'Play'} onClick={this.togglePlay}><i className="lni lni-play"></i> {this.props.label}</div>
        </div>
      )

    }
  }
  
  export default AudioPlayer;