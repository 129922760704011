import React from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

const ESCollectionNavLinks = () => {
    return ( 
        <div className="page-header fixed-top d-flex justify-content-between align-items-center container">
            <div>
                <Link to="/libraries/es" className="text-icon">
                    <i className="lni lni-chevron-left"></i>
                </Link>
            </div>
            <div className="navbar-title text-center">
                <span className="badge badge-es">ES</span> All <b>Words</b>
            </div>
            <div>
                <Link to="/" className="text-icon">
                    <i className="lni lni-more-alt"></i>
                </Link>
            </div>
        </div>
    );
};

export default ESCollectionNavLinks;