import React, { Component } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ENCollection from './components/libraries/ENCollection'
import ESCollection from './components/libraries/ESCollection'
import Home from './components/home/Home'
import Libraries from './components/libraries/Libraries'
import ENLibrary from './components/libraries/ENLibrary'
import ESLibrary from './components/libraries/ESLibrary'
import TextDetails from './components/texts/TextDetails'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import CreateText from './components/texts/CreateText'
import Stories from './components/stories/Stories'
import Store from './components/store/Store'
import More from './components/more/More'
import Profile from './components/profile/Profile'
import Tabbar from './components/layout/Tabbar'
import './App.css'


class App extends Component {
  
    render() {
      return (
        <BrowserRouter>
          <div className="App">
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/home/:toggle' component={Home} />
                <Route exact path='/libraries' component={Libraries} />
                <Route exact path='/libraries/en' component={ENLibrary} />
                <Route exact path='/libraries/es' component={ESLibrary} />
                <Route exact path='/collections/en/:id' component={ENCollection} />
                <Route exact path='/collections/es/:id' component={ESCollection} />
                <Route path='/text/:id' component={TextDetails} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signup' component={SignUp} />
                <Route path='/create' component={CreateText} />
                <Route path='/stories' component={Stories} />
                <Route path='/store' component={Store} />
                <Route path='/more' component={More} />
                <Route path='/profile' component={Profile} />
            </Switch>
          </div>
        </BrowserRouter>
      );
    }
  }

export default App;
