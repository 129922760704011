import React from "react";

const LexicalEntry = ({ lexicalEntry }) => {
    //console.log(lexicalEntry);
    const definitions = (lexicalEntry && lexicalEntry.entries && lexicalEntry.entries[0].senses && lexicalEntry.entries[0].senses[0].definitions)? lexicalEntry.entries[0].senses[0].definitions[0]: '-';
    const examples = (lexicalEntry && lexicalEntry.entries && lexicalEntry.entries[0].senses && lexicalEntry.entries[0].senses[0].examples)? lexicalEntry.entries[0].senses[0].examples[0].text : '-';

    return (
        <div className="lexical-entry-container">
            <div className="lexical-category">{lexicalEntry.lexicalCategory.text}</div>
            <p>
                <b>Definitions</b><br/>
                <span>{definitions}</span>
            </p>
            <p>
                <b>Examples</b><br/>
                <span>{examples}</span>
            </p>

        </div>
  );
};

export default LexicalEntry;
