import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom"

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }

    render() {
        const {authError, auth} = this.props;
        if(auth.uid) return <Redirect to='/home' />

        return (
            <div id="signIn" className="light container p-30 pt-0">
                <img src="logo-fig-1.svg" height="54" alt="Fig Logo" />
                <p className="page-title text-h2">Hello</p>
                <form onSubmit={this.handleSubmit}>
                    <p className="text-small">Use your credentials to sign in</p>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><div class="icon-bg"><i class="lni lni-user"></i></div></span>
                        </div>
                        <input type="email" id="email" onChange={this.handleChange} className="form-control" placeholder="Your email address" aria-label="Email" aria-describedby="basic-addon1"/>
                    </div>
                    <div class="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon2"><div class="icon-bg"><i class="lni lni-key"></i></div></span>
                        </div>
                        <input type="password" id="password" onChange={this.handleChange} className="form-control" placeholder="Your password" aria-label="Password" aria-describedby="basic-addon2"/>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block t-800">Sign In</button>
                    <div className="text-danger">
                        { authError ? <p>{authError}</p>: null }
                    </div>
                </form>
                <div class="signup-block text-center">
                    <Link to="/signup" className="">
                        <div className="btn-transparent">
                            <button className="btn btn-transparent"><span>Don't have an account? <span className="t-800">Sign Up</span></span></button>
                            </div>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log("WW ",  state);
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
