import React, { Component } from 'react'
import TextList from '../texts/TextList'
import CreateText from '../texts/CreateText'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import Tabbar from '../layout/Tabbar'
import TextFilter from '../texts/TextFilter';

class ENCollection extends Component {
    render() {
      //console.log(this.props);
      const { collection, texts, auth } = this.props;
      const collectionId = this.props.match.params.id;

      if(texts) {
        //console.log(texts.length);
      }

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div>
          <Navbar page={'en-collection'} collection={collection}/>
          <div className="collection container">
              <div className="text-small d-flex justify-content-center mb-32">
                  <span>You have {texts? texts.length: <i className="lni lni-spinner-solid"></i>} words in this collection.</span>
              </div>
              <TextFilter />
              <div className="card-columns">
                <TextList texts={texts}/>
              </div>
          </div>
          <div className={collection && collection.is_locked? 'create-text-parent fixed-bottom hidden': 'create-text-parent fixed-bottom'}>
              <CreateText lang="en" collectionId={collectionId === 'all'? '': collectionId } />
          </div>
          <Tabbar page={'en-library'}/> 
        </div>
      )
    }
  }
  
const mapStateToProps = (state) => {
  //console.log("WW", state);
  return {
    texts: state.firestore.ordered.en_texts,
    collection: state.firestore.ordered.en_collections ? state.firestore.ordered.en_collections[0] : '',
    auth: state.firebase.auth
  }
}


export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {

    //console.log("Inside firestoreConnect - ", props.match.params.id);
    var whereQueries = [
      ['user_id', '==', props.auth.uid? props.auth.uid : ''],
      ['collection_id', '==', props.match.params.id && props.match.params.id !== 'all' ? props.match.params.id : '']
    ];      

    if(props.match.params.id === 'all' || props.match.params.id === '') {
      whereQueries = [
        ['user_id', '==', props.auth.uid? props.auth.uid : '']
      ];      
    }
    //console.log(props.match.params.id);

    return [
      {
        collection: 'en_texts',
        where: whereQueries,
        orderBy: [['created_at', 'desc']]
      },
      {
        collection: 'en_collections',
        doc: props.match.params.id
      }

    ]
  })
)(ENCollection);