import React from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

const WordNavLinks = (props) => {
    
    const backUrl = props.backUrl;

    return ( 
        
        <div className="page-header fixed-top d-flex justify-content-between align-items-center">
            <div>
                <Link to={backUrl} className="text-icon">
                    <i className="lni lni-close"></i>
                </Link>
            </div>
            <div className="navbar-title text-center">
                &nbsp;
            </div>
            <div>
                <Link to="#" className="text-icon">
                    <i className="lni lni-more-alt"></i>
                </Link>
            </div>
        </div>
    );
};

export default WordNavLinks;