import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import AudioPlayer from "../media/AudioPlayer";
import LoadingButton from "../layout/LoadingButton";

const TextSummary = ({ text }) => {

  const enUSAudioPlayer = text.en_us_audio_url ? <AudioPlayer label="US" url={text.en_us_audio_url}/> : <LoadingButton />;
  const enGBAudioPlayer = text.en_gb_audio_url ? <AudioPlayer label="GB" url={text.en_gb_audio_url}/> : <LoadingButton />;
  //const enAUAudioPlayer = text.en_au_audio_url ? <AudioPlayer label="AUS" url={text.en_au_audio_url}/> : <LoadingButton />;
  //const enINAudioPlayer = text.en_in_audio_url ? <AudioPlayer label="IND" url={text.en_in_audio_url}/> : <LoadingButton />;
  const esESAudioPlayer = text.es_es_audio_url ? <AudioPlayer label="ES" url={text.es_es_audio_url}/> : <LoadingButton />;
  //const audioPlayer = text.audioUrl ? <AudioPlayer url={text.audioUrl} /> : <LoadingButton />;
  //console.log("Inside TextSummary : text.lang: ", text.lang);
  const playAudio = (e) => {
    //find the child element and trigger it's click event
    //console.log("play");
    e.currentTarget.getElementsByClassName('playIcon')[0].click();
  };
  const handleClick = (e) => {
    //console.log(e.target.id);
    const el = document.getElementById(e.target.id);
    window.location.href = '/text/' + e.target.id + '?' + el.getAttribute("data");
  }

  const link = '/text/' + text.id + '?' + JSON.stringify(text);


  return (
    <div className="card text-summary">
      <div className="card-body align-items-center">
        <div className="card-text-container">
          <div className="card-text text-center" id={text.id} data={JSON.stringify(text)}>{text.text}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="actions d-flex justify-content-center">
            {text.lang === 'es' ? <div className="actionButton">{esESAudioPlayer}</div>: ''}
            {text.lang === 'en' ? <div className="actionButton">{enUSAudioPlayer}</div>: ''}
            {text.lang === 'en' ? <div className="actionButton">{enGBAudioPlayer}</div>: ''}
          </div>    
          <div className="actions">
            <a href={link}><div className="icon-container text-center align-middle">
              <i className="lni lni-chevron-right"></i>
            </div></a>                          
          </div>  
        </div>
        </div>
    </div>
  );
};

/*
      <div className="card-footer d-flex justify-content-between">
        <small className="text-muted">{audioPlayer}</small>
        <small className="text-muted">
          {moment(text.createdAt.toDate().toString()).calendar()}
        </small>
      </div>

*/

export default TextSummary;
