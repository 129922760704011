import React from 'react';
import TextSummary from './TextSummary';

const TextList = ({texts}) => {
    return (
        <div className="text-list section">
            { texts && texts.map(text => {
                const link = '/text/' + text.id + '?' + JSON.stringify(text);
                return (
                    <TextSummary text={text} key={text.id}/>
                )
            })}
        </div>        
    );

}

export default TextList;