import React from "react";

const LibraryEntry = ({ library }) => {

    const handleClick = (e) => {
        switch (e.target.id) {
            case 'en':
                window.location.href = '/libraries/en';
                return;
            case 'es':
                window.location.href = '/libraries/es';
                return;
            default:
                return;
        }
    }

    return (
        <div className="card" id={library.lang} >
            <div className="card-title text-h1">{library.title}</div>
            <br/><br/><br/><br/>
            <div className="card-footer">
                <h6>1 Locale</h6>
                <h6>20 Collections</h6>
                <h6>1001 Words</h6>
            </div>
        </div>
  );
};

export default LibraryEntry;
