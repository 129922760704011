const initState = {
    texts: [
        {id: '1', text: 'text 1'},
        {id: '2', text: 'text 2'},
        {id: '3', text: 'text 3'},
        {id: '4', text: 'text 4'},
        {id: '5', text: 'text 5'},
        {id: '6', text: 'text 6'}
    ]
};

const textReducer = (state = initState, action) => {
    switch(action.type) {
        case 'CREATE_TEXT': 
            //console.log('created text', action.text);
            return state;
        case 'CREATE_TEXT_ERROR':    
            //console.log('created text error', action.err);
            return state;
        default:
            return state;    
    }
}

export default textReducer;