import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";
import SignedInLinks from "./SignedOutLinks";
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const HomeNavLinks = (props) => {

    const [show, setShow] = useState(true);

    const toggleMenu = () => {
        const newVal = !show;
        setShow(newVal);
        //console.log("isOpen = ", show);
        if(show) {
            document.body.classList.remove("hide-menu");
            document.body.classList.add("show-menu");
        } else {
            document.body.classList.remove("show-menu");
            document.body.classList.add("hide-menu");
        }
    }

    return ( 
        <div className="page-header fixed-top d-flex justify-content-between align-items-center container">
            <div className="hidden">
                <i id="menu-btn" onClick={toggleMenu} className="lni lni-text-align-left"></i>
                <div id="arrow-btn" onClick={toggleMenu} className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon2"><div class="icon-bg"><i class="lni lni-arrow-left"></i></div></span>
                </div>
            </div>
            <div className="navbar-title text-center">
                &nbsp;
            </div>
            <div>
                <Link to="/home" className="text-icon">
                    <i className="lni lni-search-alt"></i>
                </Link>
            </div>
            <div>
                <a onClick={props.signOut} className="pl-4" href="#">
                    <i className="lni lni-exit"></i></a>
                </div>
            
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(HomeNavLinks);

//export default HomeNavLinks;