import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createText } from '../../store/actions/textActions'
import { Redirect } from 'react-router-dom'


class CreateCollection extends Component {
    state = {
        text: '',
        lang: '',
        library_id: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if(e.target.id === 'text' && e.target.value !== '') {
            //activate the button
            document.getElementById("sendButton").style.opacity = 1;
        } else {
            //deactivate the button
            document.getElementById("sendButton").style.opacity = 0.3;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.text && this.state.text !== '') {
            this.state.text = this.state.text.trim();
            this.props.createText(this.state.library_id, this.state.lang, this.state);
            e.target.reset();
            //deactivate the button
            document.getElementById("sendButton").style.opacity = 0.3;
            window.scrollTo(0,0);
        }
    }

    render() {
        const { auth } = this.props;
        const {lang} = this.props;
        const { libraryId } = this.props;
        this.state.lang = lang;
        this.state.library_id = libraryId;

        if(!auth.uid) return <Redirect to='/signin' />

        return (
            <div id="createCollection" className="createCollection fixed-bottom container">
                <form onSubmit={this.handleSubmit}>
                    <div className="input-group input-group-lg">
                        <input type="text" id="text" className="form-control p-4" aria-label="Your new collection title" placeholder="Your new collection title" onChange={this.handleChange}></input>
                        <div className="input-group-append">
                            <button type="submit" id="createButton">
                                <img id="sendButton" alt="Send Button" src="/send.svg" height="20"/>
                            </button>
                        </div>
                    </div>
                </form>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createText: (libraryId, language, text) => dispatch(createText(libraryId, language, text))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollection)
