import React, { Component } from 'react';

class TextFilter extends Component {
  state = {
    cards: {},
    texts: {}
  }

  handleChange1 = (e) => {
    const cards = document.querySelectorAll(".text-summary");
    const titles = document.querySelectorAll(".card-text");
    cards.forEach((card, i) => {
      const text = titles[i].textContent;
      if (text.includes(e.target.value)) {
        card.classList.remove("hidden");
      } else {
        card.classList.add("hidden");
      }
    });
  }

  handleChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if(!this.state.cards.length>0) {    
      //call this only once
      this.state.cards = Array.from(document.querySelectorAll(".text-summary"));
      this.state.texts = Array.from(document.querySelectorAll(".card-text"));
    }

    this.state.cards.forEach((card, i) => {
      const text = this.state.texts[i].textContent.toLowerCase();
      if (text.includes(searchValue)) {
        card.classList.remove("hidden");
      } else {
        card.classList.add("hidden");
      }
    });
  }


  render() {
    return (
      <div class="d-flex justify-content-center mb-32">
        <div class="form-outline">
          <input type="search" id="textFilter" class="form-control" placeholder="Search" aria-label="Search" onChange={this.handleChange} />
        </div>
      </div>
    );
  }
};

export default TextFilter;