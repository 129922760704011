import React from "react";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

const StoriesNavLinks = () => {
    return ( 
        <div className="page-header fixed-top d-flex justify-content-between align-items-center container">
            <div>
                <Link to="/home" className="text-icon">
                    <i className="lni lni-chevron-left"></i>
                </Link>
            </div>
            <div className="navbar-title text-center">
                Your <b>Stories</b>
            </div>
            <div>
                <Link to="/" className="text-icon">
                    <i className="lni lni-plus"></i>
                </Link>
            </div>
        </div>
    );
};

export default StoriesNavLinks;