import React from "react";

const Error = ({error}) => {
    return ( 
        <p class="error">
            Following error has occurred.<br/>{error}
        </p>
    );
};

export default Error;