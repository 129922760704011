import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import Tabbar from '../layout/Tabbar'
import { Modal } from 'react-bootstrap'


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleClose = () => {
        this.setState({show: false});        
    }

    handleShow = () => {
        this.setState({show: true});
    }

    handleClick = (e) => {
        //console.log(e.currentTarget.id);
        window.location.href = '/collections/en/' + e.currentTarget.id;
    }
    
    render() {
      const { texts, auth, profile } = this.props;
      //console.log("I am now called ", texts, auth);

      if(!auth.uid) return <Redirect to='/signin' />

      return (
        <div>
            <Navbar page={'profile'}/>
            <div className="profile container text-center">   
                <img id="user-image" src="/assets/user_image.jpeg" width="90px" height="90px"/>
                <div className="d-flex justify-content-center mb-32">
                    <span>{profile.first_name} {profile.last_name}</span>
                </div>
                <div className="card-columns collections-list">
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-home"></i>&nbsp;&nbsp;Profile</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-list"></i>&nbsp;&nbsp;Membership</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-list"></i>&nbsp;&nbsp;Billing</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-book"></i>&nbsp;&nbsp;Purchased</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-cart"></i>&nbsp;&nbsp;Support</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-user"></i>&nbsp;&nbsp;Settings</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-user"></i>&nbsp;&nbsp;Messages</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/"><i class="lni lni-user"></i>&nbsp;&nbsp;Sign Out</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <br/><br/>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/cookies"><i class="lni lni-user"></i>&nbsp;&nbsp;Cookies</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/privacy"><i class="lni lni-user"></i>&nbsp;&nbsp;Privacy</a></div>
                            </div>   
                        </div>                   
                    </div>
                    <div class="card" id="all" onClick={this.handleClick}>
                        <div className="card-body d-flex align-items-center">
                            <div className="">
                                <div className="card-text"><a href="/terms"><i class="lni lni-user"></i>&nbsp;&nbsp;Terms of Use</a></div>
                            </div>   
                        </div>                   
                    </div>

                </div>
                <div class="d-flex app-footer align-items-center mt-2">
                    <div>
                        <img src="logo-fig-1.svg" height="54" alt="Fig Logo" />
                    </div>
                    <div className="text-left">
                        <p>Fig App</p>
                        <p><i>v1.25</i></p>
                        <span>&copy; Innovation Kitchen</span>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/><br/>
            <Tabbar page={'profile'} />
        </div>
      )
    }
  }

  const mapStateToProps = (state) => {
    //console.log("Inside Profile.js ", state);
    return {
      libraries: state.firestore.ordered.libraries,
      texts: state.firestore.ordered.texts,
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  }
  
  
  export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
      {
        collection: props.match.params.id + '_texts',
        where: [['userId', '==', props.auth.uid? props.auth.uid : '']],
        orderBy: [['createdAt',]]
      }
    ])
  )(Profile);  
