export const createText = (collectionId, language, text) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database
        const firestore = getFirestore();
        const userId = getState().firebase.auth.uid;
        //console.log(text);
        firestore.collection(language + '_texts').add({
            ...text,
            user_id: userId,
            collection_id: collectionId,
            created_at: new Date()

        }).then(()=> {
            dispatch({ type: 'CREATE_TEXT', text});
        }).catch((err)=> {
            dispatch({ type: 'CREATE_TEXT_ERROR', err});
        })
        
    }
}